'use client';

import { ErrorPage } from '@pickleballinc/features/src/error-pages/error-page';
import getCDNURL from '@pickleballinc/ui/components/pickleball-app/utils/getCDNURL';
import { Button } from '@pickleballinc/ui/components/ui/button';
import CustomLink from '@pickleballinc/ui/components/ui/custom-link';
import type { Metadata } from 'next';
import { Inter } from 'next/font/google';

import { BackButton } from '@/components/Error/back-button';

const inter = Inter({ subsets: ['latin'] });

export default function GlobalError() {
	return (
		<html lang="en">
			<head>
				<meta name="COPYRIGHT" content={`(c) 2015-${new Date().getFullYear()} Pickleball OpCo LLC`} />
				<link rel="icon" href={getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleballtournaments-app/favicon-32x32.png`, 32, 32)} />
			</head>
			<body className={`bg-gray-50 ${inter.className}`} id="body">
				<div className="flex size-full flex-1 flex-col text-gray-700 antialiased">
					<main className="h-full bg-gray-100 py-4 sm:py-6">
						<ErrorPage title="Page out of bounds." description="We couldn’t find the page you were looking for." themeColor="#254EDB">
							<BackButton />
							<CustomLink href="/">
								<Button variant="default" className="w-full bg-blue-500 text-white hover:bg-blue/80 md:w-32">
									Back to home
								</Button>
							</CustomLink>
						</ErrorPage>
					</main>
				</div>
			</body>
		</html>
	);
}

export const metadata: Metadata = {
	title: 'Find nearby Pickleball Tournaments',
	description: 'Explore Pickleball tournaments happening all over the world. Browse charity and competitive pickleball tournaments',
	keywords:
		'Pickleball News, Pro Pickleball, Tournament Site, Bracket Generator, Pickleball Tournaments, Pickleball Clinics, Pickleball League, Pickleball Brackets, Elimination Bracket, Round Robin, Pool Play, Blind Draw, Singles, Doubles, Mix Doubles, Mens, Womens, Teams, Pickleball, Pickleball Paddle, Paddle, Pickleball Registrations, USAP, Pickleball Canada',
	openGraph: {
		siteName: 'PickleballTournaments.com',
		url: `https://${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}/tournaments`,
		title: 'Find nearby Pickleball Tournaments',
		description: 'Explore Pickleball tournaments happening all over the world. Browse charity and competitive pickleball tournaments',
		images: [
			{
				url: `https://${process.env.NEXT_PUBLIC_FRONT_URL}/pickleball.png`
			}
		],
		type: 'website'
	},
	twitter: {
		card: 'summary_large_image',
		site: 'pickleball',
		title: 'Find nearby Pickleball Tournaments',
		description: 'Explore Pickleball tournaments happening all over the world. Browse charity and competitive pickleball tournaments',
		images: [
			{
				url: `https://${process.env.NEXT_PUBLIC_FRONT_URL}/pickleball.png`
			}
		]
	},
	icons: [
		{
			rel: 'apple-touch-icon',
			url: `${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleballtournaments-app/apple-touch-icon.png`
		},
		{
			rel: 'icon',
			type: 'image/png',
			sizes: '32x32',
			url: `${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleballtournaments-app/favicon-32x32.png`
		},
		{
			rel: 'icon',
			type: 'image/png',
			sizes: '16x16',
			url: `${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleballtournaments-app/favicon-16x16.png`
		},
		{
			rel: 'icon',
			url: `${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleballtournaments-app/favicon.ico`
		}
	]
};
